import AutoSubmit from '@stimulus-components/auto-submit'

// Extended from: https://www.stimulus-components.com/docs/stimulus-auto-submit
//
// Configuration
// data-auto-submit-delay-value: Delay (in ms) before actually submit the form. (0 to disable)
// data-auto-submit-auto-add-actions-value: automatically adds trigger on form elements
// data-auto-submit-add-trigger-event-type-value: adds a hidden field (event_name) containing the event that triggered the submit
export default class extends AutoSubmit {
  static values = {
    delay: {
      type: Number,
      default: 500,
    },
    autoAddActions: {
      type: Boolean,
      default: true,
    },
    addTriggerEventType: {
      type: Boolean,
      default: false,
    }
  }

  addDataAction(formElement) {
    if (
      formElement.classList.contains('local') ||
      ['hidden', 'submit'].includes(formElement.type)
    ) {
      return;
    }

    if (
      ['text', 'number'].includes(formElement.type) ||
      formElement.nodeName === 'TEXTAREA'
    ) {
      formElement.setAttribute('data-action', 'change->auto-submit#submit keyup->auto-submit#submit');
    }
    else {
      formElement.setAttribute('data-action', 'change->auto-submit#submit');
    }
  }

  connect() {
    super.connect();

    if (this.autoAddActionsValue) {
      const form_elements = [...this.element.elements].filter((formElement) =>
        ['INPUT', 'SELECT', 'TEXTAREA'].includes(formElement.nodeName)
      );
      form_elements.forEach((formElement) => this.addDataAction(formElement));
    }
  }

  submit(event) {
    if (this.addTriggerEventTypeValue) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = 'event_name';
      hiddenField.value = event.type;

      this.element.appendChild(hiddenField);
    }
    super.submit();
  }
}
